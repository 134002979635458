import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { MenuContainer } from './components/MenuContainer'
import { Navbar } from './components/Navbar'
import { theme } from './miscellaneous/theme'
import { Home } from './pages/Home'
import { Blogs } from './pages/Blogs'
import { SinglePost } from './pages/SinglePost'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const App = () => {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: 60000
      }
    }
  })

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar />
          <MenuContainer />
          <Routes>
            <Route path='/' element={<Home />} />

            <Route path='/blogs' element={<Blogs />} />
            <Route path='/blog/:slug' element={<SinglePost />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  )
}
