import React from 'react'
import { About } from '../sections/About'
import { Hero } from '../sections/Hero'
import { Portfolio } from '../sections/Portfolio'
import { Contact } from '../sections/Contact'
import { Watermark } from '../components/Watermark'

export const Home = () => {
    return (
        <>
            <Watermark />
            <Hero />
            <About />
            <Portfolio />
            <Contact />
        </>
    )
}
