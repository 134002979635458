import { Flex } from '@chakra-ui/react'
import { useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';
import { allBreakpoints } from '../miscellaneous/breakpoints';
import { SectionTitle } from '../components/SectionTitle'
import { BlogPost } from '../components/BlogPost'

export const Blogs = () => {

    // GET THE STATES FROM THE CONTEXT
    const { setActiveNav, setToggled } = useContext(ApplicationContext)

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: '-50% 0px -50% 0px'
    });

    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        setToggled(false)

        if (isInView) {
            setActiveNav(-1);
        }
    }, [isInView, setActiveNav, setToggled])

    return (
        <Flex
            justifyContent='center'
            alignItems='center'
            ref={ref}
            paddingBlock='8rem'
        >
            <Flex
                w={allBreakpoints}
                margin='auto'
                justifyContent='center'
                alignItems='center'
                flexDir='column'
                gap='5rem'
            >
                <SectionTitle title='My Blogs'/>
                <BlogPost/>
            </Flex>
        </Flex>
    )
}
