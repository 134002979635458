import { AspectRatio, Box, Button, Flex, Image, Skeleton, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import client from '../client'
import { allBreakpoints } from '../miscellaneous/breakpoints'

import BlockContent from '@sanity/block-content-to-react'
import { useInView } from 'framer-motion'
import { ApplicationContext } from '../context/AppContext'

import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useQuery } from '@tanstack/react-query'

export const SinglePost = () => {

    const navigate = useNavigate();
    const { setActiveNav } = useContext(ApplicationContext)

    const { slug } = useParams()

    const fetchSingleBlog = () => {
        return client.fetch(
            `*[slug.current == "${slug}"] {
                title,
                body,
                mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                }
            }`
        )
    }

    const { data: singlePost, isFetching } = useQuery({
        queryKey: [slug],
        queryFn: fetchSingleBlog
    })

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref)

    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setActiveNav(-1);
        }
    }, [isInView, setActiveNav])


    return (
        <Flex
            justifyContent='center'
            alignItems='center'
            paddingBlock='10rem'
            ref={ref}
        >
            <Flex
                w={allBreakpoints}
                margin='auto'
                justifyContent='center'
                alignItems='center'
                flexDir='column'
                gap='2rem'
            >
                {isFetching ? (
                    <Skeleton h='100%' />
                ) : (
                    <>
                        <Button
                            alignSelf='start'
                            leftIcon={<AiOutlineArrowLeft />}
                            onClick={() => {
                                navigate(`/blogs`)
                            }}
                        >
                            Back
                        </Button>
                        <Text
                            fontSize='2.6rem'
                            fontWeight='black'
                            textAlign='center'
                        >
                            {singlePost[0]?.title}
                        </Text>
                        <AspectRatio
                            ratio={16 / 9}
                            w={{
                                base: '90vw',
                                md: '100%',
                                xl: '80%'
                            }}
                        >
                            <Image
                                src={singlePost[0]?.mainImage?.asset?.url}
                                alt={singlePost.title}
                                borderRadius='.3rem'
                                boxShadow='2px 2px 18px rgba(0, 0, 0, .2)'
                            />
                        </AspectRatio>
                        <Box
                            lineHeight='1.6'
                            fontSize='1.1rem'
                            textAlign='justify'
                            w={{
                                md: '100%',
                                xl: '80%'
                            }}
                            sx={{
                                h3: {
                                    fontSize: '1.6rem',
                                    fontWeight: 'semibold',
                                    color: 'palette.accent',
                                    marginTop: '1.5rem'
                                },
                                ul: {
                                    marginTop: '1rem',
                                    marginLeft: '2rem',
                                }
                            }}
                        >
                            <BlockContent
                                blocks={singlePost[0]?.body}
                                projectId='xd7xjlug'
                                dataset='production'
                            />
                        </Box>
                    </>
                )}
            </Flex>
        </Flex>
    )
}
