import { Flex, Image } from '@chakra-ui/react'
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';
import { allBreakpoints } from '../miscellaneous/breakpoints';
import { SectionTitle } from '../components/SectionTitle'
import { RightContents } from '../components/RightContents';

import ProfilePic2 from '../assets/ProfilePic2.png'
import { Statistics } from '../components/Statistics';
import { item2 } from '../miscellaneous/motionVariants';

export const About = () => {

    // GET THE STATES FROM THE CONTEXT
    const { setActiveNav } = useContext(ApplicationContext)

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: '-50% 0px -50% 0px'
    });

    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setActiveNav(1);
        }
    }, [isInView, setActiveNav])

    return (
        <Flex
            id='about'
            paddingBlock='5rem'
            justifyContent='center'
            alignItems='center'
            ref={ref}
            zIndex='-1'
            bg='#E5E5E5'
        >
            <Flex
                w={allBreakpoints}
                margin='auto'
                flexDir='column'
                justifyContent='center'
                alignItems='center'
                gap='5rem'
            >
                <SectionTitle title={'About Me'} />
                <Flex
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                    gap='5rem'
                    flexDir={{
                        base: 'column',
                        md: 'row'
                    }}
                >
                    <Flex
                        flex={1}
                        justifyContent='start'
                        alignItems='center'

                        as={motion.div}
                        variants={item2}
                        initial='hidden'
                        whileInView='show'
                        viewport={{ once: true }}
                    >
                        <Image src={ProfilePic2} />
                    </Flex>
                    <RightContents />
                </Flex>
                <Statistics />
            </Flex>
        </Flex>
    )
}
