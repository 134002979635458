import { Button } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { AiFillMail } from 'react-icons/ai'

export const HeroButton = () => {
    return (
        <Button
            bg='palette.accent'
            borderRadius='.3rem'
            width='15rem'
            marginTop='1.5rem'
            p='2rem 1rem'
            _hover={{}}
            pos='relative'
            _active={{}}
            color='palette.primary'
            fontSize='1.2rem'
            as={motion.a}
            href='#contact'
            leftIcon={<AiFillMail fontSize='1.5rem'/>}

            whileTap={{
                scale: .90
            }}
        >
            Contact Me
        </Button>
    )
}
