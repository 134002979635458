import { AspectRatio, Button, Flex, Image, Skeleton, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Link } from 'react-router-dom'
import client from '../client'

export const BlogPost = () => {

    const fetchBlogs = () => {
        return client.fetch(
            `*[_type == "post"]{
                title,
                slug,
                mainImage{
                    asset -> {
                        _id,
                        url
                    },
                    alt
                },
                publishedAt
            }`
        )
    }

    const { data: posts, isFetching } = useQuery({
        queryKey: ['blogs'],
        queryFn: fetchBlogs
    })

    return (
        <Wrap
            w='100%'
        >
            {isFetching ? (
                <Skeleton h='100%'/>
            ) : (
                posts?.map((post) => {
                    return (
                        <WrapItem
                            key={post.slug.current}
                            p='1rem'
                        >
                            <Flex
                                w='100%'
                                bg='#E5E5E5'
                                p='1rem'
                                justifyContent='start'
                                flexDir='column'
                                alignItems='start'
                                borderRadius='.3rem'
                                gap='1.2rem'
                                boxShadow='2px 2px 18px rgba(0, 0, 0, .2)'
                            >
                                <AspectRatio
                                    ratio={16 / 9}
                                    w={{
                                        base: '90vw',
                                        md: '20rem'
                                    }}
                                >
                                    <Image
                                        src={post.mainImage.asset.url}
                                        alt={post.title}
                                    />
                                </AspectRatio>
                                <Text
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                >
                                    {post.title}
                                </Text>
                                <Flex
                                    w='100%'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <Button
                                        bg='palette.accent'
                                        color='white'
                                        _hover={{}}
                                    >
                                        <Link to={`/blog/${post.slug.current}`}>
                                            Read Full Article
                                        </Link>
                                    </Button>
                                    {/* <Text>
                                        { post.publishedAt }
                                    </Text> */}
                                </Flex>
                            </Flex>
                        </WrapItem>
                    )
                })
            )}
        </Wrap>
    )
}
