import { Button } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineDownload } from 'react-icons/ai'

import Resume from '../assets/NANKWAI_RESUME.pdf'

export const AboutButton = () => {
    return (
        <Button
            bg='transparent'
            border='1px solid'
            borderColor='palette.accent'
            borderRadius='.3rem'
            width='15rem'
            marginTop='1.5rem'
            p='2rem 1rem'
            _hover={{}}
            pos='relative'
            _active={{}}
            color='palette.accent'
            fontSize='1.2rem'
            fontWeight='bold'
            as='a'
            target='_blank'
            href={Resume}
            download
            leftIcon={<AiOutlineDownload fontSize='1.5rem'/>}
        >
            Resume
        </Button>
    )
}
