import { Flex, Text } from '@chakra-ui/react'
import { useInView } from 'framer-motion';
import React, { useRef } from 'react'

import CountUp from 'react-countup'

export const Statistics = () => {

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true })

    return (
        <Flex
            gap={{
                base: '2rem',
                md: '4rem'
            }}
            justifyContent='center'
            alignItems='center'
            ref={ref}
        >
            <Flex
                flexDir='column'
                p='1.5rem'
                justifyContent='center'
                alignItems='center'
            >
                <Text
                    fontSize='2.6rem'
                    fontWeight='bold'
                    color='palette.accent'
                >
                    {isInView && (
                        <>
                            <CountUp
                                start={0}
                                end={4}
                            />
                            +
                        </>
                    )}
                </Text>
                <Text
                    textAlign='center'
                    fontWeight='semibold'
                    fontSize='1rem'
                    color='palette.accent'
                >
                    Years of Experience
                </Text>
            </Flex>
            <Flex
                flexDir='column'
                p='1.5rem'
                justifyContent='center'
                alignItems='center'
            >
                <Text
                    fontSize='2.6rem'
                    fontWeight='bold'
                    color='palette.accent'
                >
                    {isInView && (
                        <>
                            <CountUp
                                start={0}
                                end={90}
                            />
                            +
                        </>
                    )}
                </Text>
                <Text
                    textAlign='center'
                    fontWeight='semibold'
                    fontSize='1rem'
                    color='palette.accent'
                >
                    Happy Clients
                </Text>
            </Flex>
            <Flex
                flexDir='column'
                p='1.5rem'
                justifyContent='center'
                alignItems='center'
            >
                <Text
                    fontSize='2.6rem'
                    fontWeight='bold'
                    color='palette.accent'
                >
                    {isInView && (
                        <>
                            <CountUp
                                start={0}
                                end={120}
                            />
                            +
                        </>
                    )}
                </Text>
                <Text
                    textAlign='center'
                    fontWeight='semibold'
                    fontSize='1rem'
                    color='palette.accent'
                >
                    Finished Tasks
                </Text>
            </Flex>
        </Flex>
    )
}
