import { Box, Flex, Link } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ApplicationContext } from '../context/AppContext'

export const MenuContainer = () => {

    const navigate = useNavigate()

    const { toggled, setToggled } = useContext(ApplicationContext);

    const navLinks = [
        { link: '#hero', label: 'Home' },
        { link: '#about', label: 'About' },
        { link: '#portfolio', label: 'Portfolio' },
    ]

    return (
        <Flex
            h='100vh'
            w='100%'
            pos='fixed'
            bg='#101010'
            justifyContent='center'
            alignItems='center'
            gap='1.5rem'
            zIndex={2}
            flexDir='column'
            as={motion.div}
            initial={{
                x: '100%'
            }}
            animate={{
                x: toggled ? 0 : '100%',
                opacity: toggled ? 1 : 0,
                transition: {
                    duration: .3
                }
            }}
        >
            {navLinks.map((item, index) => {
                return (
                    <Link
                        href={item.link}
                        color='palette.primary'
                        fontWeight='semibold'
                        key={index}

                        onClick={() => {
                            setToggled(!toggled)
                            navigate('/')
                        }}
                    >
                        {item.label}
                    </Link>
                )
            })}
            <Box
                bg='palette.accent'
                p='.5rem 1.6rem'
                borderRadius='.3rem'
                color='white'
                transition='all .3s ease'

                _hover={{
                    color: 'white',
                    bg: 'palette.accent'
                }}
            >
                <NavLink
                    to='/blogs'
                >
                    Blogs
                </NavLink>
            </Box>
        </Flex>
    )
}
