import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const Watermark = () => {
    return (
        <Box
            pos='absolute'
            top='10rem'
            left='0'
        >
            <Text
                color='#E9E9E9'
                fontSize={{
                    '2xl': '9rem',
                    md: '7rem',
                    base: '5.5rem'
                }}
                fontWeight='normal'
            >
                Data Analyst
            </Text>
        </Box>
    )
}
