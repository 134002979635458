import { Box } from '@chakra-ui/react'
import React from 'react'
import { motion } from 'framer-motion'

export const Illustration = () => {
    return (
        <Box
            w='90%'
            h='90%'
        >
            <svg viewBox="0 0 481 339" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Illustration" clipPath="url(#clip0_40_14)">
                    <motion.g id="Element2"
                        initial={{
                            y: -20,
                            opacity: .6
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            repeatType: "reverse",
                            repeat: Infinity,
                            duration: 1.6,
                            ease: 'easeIn'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                    >
                        <path id="Vector" d="M191.489 339.399H67V202H191.489V339.399Z" fill="#D9D9D9" fillOpacity="0.71" />
                        <path id="Vector_2" d="M174.559 326.422H148.665V327.479H174.559V326.422Z" fill="#3F3D56" />
                        <path id="Vector_3" d="M174.559 329.593H148.665V330.65H174.559V329.593Z" fill="#3F3D56" />
                        <path id="Vector_4" d="M174.559 332.764H148.665V333.822H174.559V332.764Z" fill="#3F3D56" />
                        <path id="Vector_5" d="M129.245 226.571V237.603C135.745 237.603 142.099 239.544 147.504 243.181C152.908 246.817 157.121 251.986 159.608 258.034C162.096 264.081 162.746 270.736 161.478 277.156C160.21 283.576 157.08 289.473 152.484 294.102L160.23 301.902C166.359 295.731 170.532 287.868 172.223 279.308C173.914 270.748 173.046 261.875 169.729 253.812C166.413 245.749 160.796 238.857 153.59 234.008C146.384 229.159 137.912 226.571 129.245 226.571Z" fill="#E6E6E6" />
                        <path id="Vector_6" d="M160.23 301.902L152.484 294.102C146.865 299.761 139.4 303.178 131.471 303.719C123.542 304.261 115.687 301.89 109.361 297.046L102.73 305.832C111.166 312.289 121.64 315.448 132.212 314.726C142.784 314.004 152.738 309.448 160.23 301.902Z" fill="#00C05D" />
                        <path id="Vector_7" d="M96.3795 270.699C96.3795 266.353 97.2296 262.049 98.8812 258.034C100.533 254.019 102.954 250.37 106.005 247.297C109.057 244.224 112.68 241.786 116.668 240.122C120.655 238.459 124.929 237.603 129.245 237.603V226.571C120.071 226.571 111.129 229.47 103.679 234.859C96.2288 240.249 90.6459 247.857 87.7174 256.611C84.7889 265.366 84.6624 274.825 87.3558 283.655C90.0492 292.486 95.4266 300.242 102.73 305.832L109.361 297.046C105.324 293.962 102.05 289.979 99.7982 285.408C97.546 280.836 96.3758 275.802 96.3795 270.699V270.699Z" fill="#399465" />
                    </motion.g>
                    <motion.g id="Element3"
                        initial={{
                            y: -10,
                            opacity: 1
                        }}
                        animate={{
                            y: 0,
                            opacity: .5
                        }}
                        transition={{
                            repeatType: "reverse",
                            repeat: Infinity,
                            duration: 2.6,
                            ease: 'easeOut'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                    >
                        <path id="Vector_8" d="M372.489 243.399H248V106H372.489V243.399Z" fill="#E1E1E1" fillOpacity="0.35" />
                        <path id="Vector_9" d="M288.335 189.994H265.926V226.099H288.335V189.994Z" fill="#399465" fillOpacity="0.33" />
                        <path id="Vector_10" d="M320.702 165.423H298.294V226.099H320.702V165.423Z" fill="#399465" fillOpacity="0.7" />
                        <path id="Vector_11" d="M353.069 136.84H330.661V226.099H353.069V136.84Z" fill="#399465" />
                        <path id="Vector_12" d="M360.538 227.102H259.951V122.297H260.947V226.099H360.538V227.102Z" fill="#797979" fillOpacity="0.4" />
                    </motion.g>
                    <g id="Person">
                        <path id="Vector_13" d="M424.771 330.478L416.724 330.477L412.896 299.221L424.772 299.221L424.771 330.478Z" fill="#A58275" />
                        <path id="Vector_14" d="M426.823 338.333L400.876 338.332V338.001C400.876 335.304 401.94 332.717 403.834 330.81C405.728 328.903 408.297 327.831 410.975 327.831H410.976L426.824 327.832L426.823 338.333Z" fill="#2F2E41" />
                        <path id="Vector_17" d="M367.633 176.762L365.128 200.026L466.388 198.571L422.228 126.111L386.172 124.807L367.633 176.762Z" fill="#2F2E41" />
                        <path id="Vector_18" opacity="0.28" d="M367.633 176.762L365.128 200.026L466.388 198.571L422.228 126.111L386.172 124.807L367.633 176.762Z" fill="black" />
                        <path id="Vector_19" d="M387.545 180.756L383.97 202.042V221.069L412.793 230.543C412.793 230.543 409.418 304.446 413.917 309.153L413.806 320.516L426.38 321.091L426.483 313.095L431.813 253.32L434.953 209.434L429.863 176.161L387.545 180.756Z" fill="#2F2E41" />
                        <path id="Vector_21" d="M382.372 93.7488L371.203 92.7093L361.072 94.4095L339.62 94.4164L337.792 110.074L357.369 114.485L362.14 112.131L382.026 119.761L382.372 93.7488Z" fill="#2F2E41" />
                        <path id="Vector_22" d="M395.712 93.6997C395.712 93.6997 419.416 91.4631 424.806 95.7281C430.196 99.993 441.747 184.516 441.747 184.516L395.712 187.133L387.459 181.415L389.802 139.217L395.712 93.6997Z" fill="#CCCCCC" />
                        <path id="Vector_23" d="M402.947 93.1732L376.8 93.7511L352.422 204.678L371.673 208.943L402.947 93.1732Z" fill="#2F2E41" />
                        <path id="Vector_24" d="M421.535 92.2356L442.131 98.7273L440.961 144.303L466.388 198.793L436.497 201.964L412.87 143.125L421.535 92.2356Z" fill="#2F2E41" />
                        <path id="Vector_25" d="M455.498 204.328C456.22 203.688 456.794 202.896 457.18 202.009C457.565 201.121 457.752 200.159 457.729 199.191C457.705 198.222 457.47 197.271 457.042 196.403C456.614 195.536 456.002 194.774 455.249 194.17L459.393 179.614L451.222 174.921L445.658 195.56C444.735 196.9 444.332 198.535 444.526 200.154C444.72 201.774 445.498 203.265 446.711 204.346C447.924 205.426 449.488 206.022 451.108 206.018C452.727 206.015 454.29 205.414 455.498 204.328V204.328Z" fill="#A58275" />
                        <path id="Vector_26" d="M430.196 99.993L442.383 98.9285C442.383 98.9285 453.324 110.452 455.813 119.374C458.302 128.297 460.998 156.213 460.998 156.213L459.873 197.051L445.628 187.309L444.672 152.183L430.196 99.993Z" fill="#2F2E41" />
                        <path id="Vector_27" d="M427.256 79.4666C431.833 71.036 428.756 60.4655 420.384 55.8569C412.012 51.2483 401.516 54.3467 396.939 62.7774C392.363 71.208 395.439 81.7784 403.811 86.3871C412.183 90.9957 422.68 87.8973 427.256 79.4666Z" fill="#A58275" />
                        <path id="Vector_28" d="M410.172 63.8591C407.905 63.3317 405.552 63.9161 403.256 64.295C400.96 64.6739 398.431 64.7988 396.489 63.5089C395.054 62.5563 394.173 60.9803 393.345 59.4644C392.999 58.923 392.769 58.3154 392.668 57.6799C392.571 56.5587 393.279 55.5359 394.031 54.7034C397.524 50.8366 402.629 48.7202 407.777 48.0479C412.023 47.4107 416.36 47.9441 420.329 49.5914C424.237 51.3174 427.596 54.4783 429.133 58.4873C429.84 60.5391 430.282 62.6743 430.447 64.84C430.679 66.4334 438.408 101.674 438.234 103.275C438.027 104.461 426.473 78.5816 426.064 79.714L435.935 98.1803C435.752 98.9116 411.103 91.6967 426.064 82.8703C428.118 92.623 427.658 93.5492 425.124 83.4338C424.759 83.5145 424.469 92.779 424.128 92.623C424.332 91.7824 424.272 81.6034 424.128 80.7504C424.048 80.3252 423.844 79.9338 423.542 79.6266C423.239 79.3194 422.852 79.1104 422.431 79.0266C421.493 78.9264 420.691 79.6587 419.801 79.974C419.394 80.0956 418.967 80.1352 418.545 80.0906C418.122 80.046 417.713 79.918 417.339 79.714C416.966 79.5099 416.636 79.2338 416.369 78.9015C416.101 78.5691 415.902 78.1869 415.781 77.7768C415.757 77.6941 415.735 77.6106 415.718 77.5261C415.577 76.6414 415.813 75.6942 415.48 74.8634C415.09 73.8899 414.055 73.3798 413.278 72.6803C412.569 72.0212 412.061 71.1718 411.815 70.2321C411.568 69.2924 411.593 68.3014 411.887 67.3756C412.095 66.7316 412.683 66.4387 412.565 65.6989C412.411 64.7282 410.99 64.0496 410.172 63.8591Z" fill="#2F2E41" />
                        <path id="Vector_29" d="M339.507 108.713C343.294 108.713 346.365 105.621 346.365 101.806C346.365 97.9918 343.294 94.8996 339.507 94.8996C335.719 94.8996 332.648 97.9918 332.648 101.806C332.648 105.621 335.719 108.713 339.507 108.713Z" fill="#A58275" />
                        <path id="Vector_30" d="M480.428 339H373.551C373.399 339 373.254 338.939 373.147 338.831C373.04 338.723 372.98 338.577 372.98 338.424C372.98 338.272 373.04 338.125 373.147 338.017C373.254 337.91 373.399 337.849 373.551 337.849H480.428C480.58 337.849 480.725 337.91 480.833 338.017C480.94 338.125 481 338.272 481 338.424C481 338.577 480.94 338.723 480.833 338.831C480.725 338.939 480.58 339 480.428 339Z" fill="#CCCCCC" />
                        <motion.g
                            initial={{
                                rotate: -10,
                                x: 10,
                                y: -5
                            }}
                            animate={{
                                rotate: 0,
                                x: 0,
                            }}
                            transition={{
                                repeatType: "reverse",
                                repeat: Infinity,
                                duration: 2.6,
                                ease: 'easeOut'
                            }}
                        >
                            <path id="Vector_15" d="M460.287 310.555L455.064 316.72L428.967 299.363L436.677 290.266L460.287 310.555Z" fill="#A58275" />
                            <path id="Vector_16" d="M467.553 314.082L450.71 333.958L450.461 333.744C448.423 331.993 447.16 329.499 446.948 326.81C446.737 324.121 447.595 321.458 449.333 319.406L449.334 319.405L459.621 307.266L467.553 314.082Z" fill="#2F2E41" />
                            <path id="Vector_20" d="M409.875 199.079L383.97 221.069C383.97 221.069 382.454 260.742 386.304 265.395C389.611 269.391 432.62 303.86 432.62 303.86C432.62 303.86 440.264 310.921 444.548 311.034C448.832 311.146 453.814 302.098 453.814 302.098L443.893 294.508C443.893 294.508 418.191 270.348 410.56 260.742C407.48 256.865 407.48 256.477 407.48 256.477L408.532 240.354L419.12 210.43L409.875 199.079Z" fill="#2F2E41" />
                        </motion.g>
                    </g>
                    <motion.g id="Element1"
                        initial={{
                            y: 20,
                            opacity: .5
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            repeatType: "reverse",
                            repeat: Infinity,
                            duration: 2.1,
                            ease: 'easeIn'
                        }}
                        whileHover={{
                            scale: 1.1
                        }}
                    >
                        <path id="Vector_31" d="M218.105 140.399H0V3H218.105V140.399Z" fill="#DBDBDB" fillOpacity="0.58" />
                        <path id="Vector_32" d="M187.816 111.663H30.2896C30.1589 111.663 30.0335 111.611 29.941 111.518C29.8486 111.425 29.7966 111.299 29.7966 111.167V35.1343C29.7966 35.0027 29.8486 34.8764 29.941 34.7833C30.0335 34.6902 30.1589 34.6379 30.2896 34.6379C30.4204 34.6379 30.5458 34.6902 30.6382 34.7833C30.7307 34.8764 30.7826 35.0027 30.7826 35.1343V110.67H187.816C187.881 110.67 187.945 110.683 188.005 110.708C188.065 110.733 188.119 110.769 188.165 110.815C188.211 110.861 188.248 110.916 188.273 110.977C188.297 111.037 188.31 111.102 188.31 111.167C188.31 111.232 188.297 111.297 188.273 111.357C188.248 111.418 188.211 111.472 188.165 111.519C188.119 111.565 188.065 111.601 188.005 111.626C187.945 111.651 187.881 111.664 187.816 111.663V111.663Z" fill="#3F3D56" />
                        <path id="Vector_33" d="M63.9461 106.202H49.6185C49.2305 106.202 48.8585 106.047 48.5842 105.77C48.3098 105.494 48.1555 105.12 48.155 104.729V84.8394C48.1555 84.4487 48.3098 84.0742 48.5842 83.7979C48.8585 83.5217 49.2305 83.3663 49.6185 83.3658H63.9461C64.334 83.3663 64.706 83.5217 64.9803 83.7979C65.2547 84.0742 65.409 84.4487 65.4095 84.8394V104.729C65.409 105.12 65.2547 105.494 64.9804 105.77C64.706 106.047 64.3341 106.202 63.9461 106.202V106.202Z" fill="#32B170" />
                        <path id="Vector_34" d="M90.0743 106.202H75.7467C75.3587 106.202 74.9867 106.047 74.7124 105.77C74.438 105.494 74.2837 105.12 74.2832 104.729V65.9744C74.2837 65.5837 74.438 65.2092 74.7124 64.9329C74.9867 64.6567 75.3587 64.5013 75.7467 64.5009H90.0743C90.4622 64.5013 90.8342 64.6567 91.1085 64.9329C91.3829 65.2092 91.5372 65.5837 91.5377 65.9744V104.729C91.5372 105.12 91.3829 105.494 91.1085 105.77C90.8342 106.047 90.4622 106.202 90.0743 106.202V106.202Z" fill="#32B170" />
                        <path id="Vector_35" d="M116.202 106.202H101.875C101.487 106.202 101.115 106.047 100.841 105.77C100.566 105.494 100.412 105.12 100.411 104.729V84.8394C100.412 84.4487 100.566 84.0742 100.841 83.7979C101.115 83.5217 101.487 83.3663 101.875 83.3658H116.202C116.59 83.3663 116.962 83.5217 117.237 83.7979C117.511 84.0742 117.665 84.4487 117.666 84.8394V104.729C117.665 105.12 117.511 105.494 117.237 105.77C116.962 106.047 116.59 106.202 116.202 106.202V106.202Z" fill="#32B170" />
                        <path id="Vector_36" d="M142.331 106.202H128.003C127.624 106.211 127.257 106.067 126.983 105.804C126.708 105.54 126.549 105.177 126.54 104.796V58.461C126.549 58.0792 126.708 57.7168 126.983 57.4531C127.257 57.1894 127.624 57.0459 128.003 57.0542H142.331C142.71 57.0459 143.077 57.1894 143.351 57.4531C143.625 57.7168 143.785 58.0792 143.794 58.461V104.796C143.785 105.177 143.625 105.54 143.351 105.804C143.077 106.067 142.71 106.211 142.331 106.202V106.202Z" fill="#399465" />
                        <path id="Vector_37" d="M168.459 106.202H154.131C153.743 106.202 153.371 106.047 153.097 105.77C152.823 105.494 152.668 105.12 152.668 104.729V45.1236C152.668 44.7329 152.823 44.3584 153.097 44.0821C153.371 43.8059 153.743 43.6505 154.131 43.6501H168.459C168.847 43.6505 169.219 43.8059 169.493 44.0821C169.767 44.3584 169.922 44.7329 169.922 45.1236V104.729C169.922 105.12 169.767 105.494 169.493 105.77C169.219 106.047 168.847 106.202 168.459 106.202V106.202Z" fill="#148249" />
                        <path id="Vector_38" d="M56.7823 77.4085C58.4159 77.4085 59.7402 76.0749 59.7402 74.4298C59.7402 72.7847 58.4159 71.4511 56.7823 71.4511C55.1487 71.4511 53.8244 72.7847 53.8244 74.4298C53.8244 76.0749 55.1487 77.4085 56.7823 77.4085Z" fill="#3F3D56" />
                        <path id="Vector_39" d="M82.9104 58.047C84.5441 58.047 85.8684 56.7134 85.8684 55.0684C85.8684 53.4233 84.5441 52.0897 82.9104 52.0897C81.2768 52.0897 79.9525 53.4233 79.9525 55.0684C79.9525 56.7134 81.2768 58.047 82.9104 58.047Z" fill="#3F3D56" />
                        <path id="Vector_40" d="M109.039 77.4085C110.672 77.4085 111.997 76.0749 111.997 74.4298C111.997 72.7847 110.672 71.4511 109.039 71.4511C107.405 71.4511 106.081 72.7847 106.081 74.4298C106.081 76.0749 107.405 77.4085 109.039 77.4085Z" fill="#3F3D56" />
                        <path id="Vector_41" d="M135.167 48.6145C136.8 48.6145 138.125 47.2809 138.125 45.6358C138.125 43.9908 136.8 42.6572 135.167 42.6572C133.533 42.6572 132.209 43.9908 132.209 45.6358C132.209 47.2809 133.533 48.6145 135.167 48.6145Z" fill="#3F3D56" />
                        <path id="Vector_42" d="M161.295 37.6927C162.929 37.6927 164.253 36.3591 164.253 34.714C164.253 33.0689 162.929 31.7353 161.295 31.7353C159.661 31.7353 158.337 33.0689 158.337 34.714C158.337 36.3591 159.661 37.6927 161.295 37.6927Z" fill="#3F3D56" />
                        <path id="Vector_43" d="M109.101 75.0971L82.9106 55.3424L57.0777 74.8271L56.4867 74.0323L82.9106 54.1015L108.976 73.7624L134.879 45.2179L134.983 45.1753L161.111 34.5736L161.479 35.4951L135.455 46.0542L109.101 75.0971Z" fill="#3F3D56" />
                    </motion.g>
                </g>
                <defs>
                    <clipPath id="clip0_40_14">
                        <rect width="481" height="339" fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </Box>
    )
}
