
import { extendTheme } from "@chakra-ui/react"

const colors = {
    palette: {
        primary: '#F1F1F1',
        secondary: '#EDEDED',
        tertiary: '#464646',
        accent: '#337A55'
    }
}

const fonts = {
    body: `'Nunito', sans-serif`
}

const styles = {
    global: {
        html: {
            scrollBehavior: 'smooth',
            overflowX: 'hidden', 
        },
        body: {
            bg: 'palette.primary',
            color: 'palette.tertiary',
        },
        _placeholder: {
            color: 'rgba(38, 50, 56, .6)',
            fontSize: '.9rem'
        },
        h1: {
            fontSize: '4.2rem',
            fontWeight: 'black',
            background: 'linear-gradient(to right, #2C6246, #399465)',
            backgroundClip: 'text',
        },
    }
}

export const theme = extendTheme({ colors, fonts, styles });