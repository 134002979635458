import { Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { item3 } from '../miscellaneous/motionVariants'
import { AboutButton } from './AboutButton'

export const RightContents = () => {
    return (
        <Flex
            flexDir='column'
            flex={1}
            zIndex='1'
            alignItems={{
                base: 'center',
                md: 'start'
            }}

            as={motion.div}
            variants={item3}
            initial='hidden'
            whileInView='show'
            viewport={{ once: true }}
        >
            <Text
                as='h1'
                textAlign={{
                    base: 'center',
                    md: 'start'
                }}
            >
                Nankwai kwalmi
            </Text>
            <Text
                width='100%'
                as='h5'
                fontWeight='semibold'
                textAlign={{
                    base: 'center',
                    md: 'start'
                }}
            >
                Hi, I'm a Business Data Analyst with more than 4 years of experience. By gathering, processing, analyzing, and presenting data, I can provide insights, analytics, and business intelligence that can be used for process reengineering and opportunity identification.
                Key achievement built automated reporting dashboards using Tableau and Power BI, hence reducing report creation time from 4.8 hours to 25 minutes per report.
            </Text>
            <AboutButton />
        </Flex>
    )
}
