import { Flex, Text } from '@chakra-ui/react'
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react'
import { ApplicationContext } from '../context/AppContext';

import { allBreakpoints } from '../miscellaneous/breakpoints'
import { HeroButton } from '../components/HeroButton'

import Typewriter from 'typewriter-effect'
import { Illustration } from '../assets/svg_assets/Illustration';
import { container, item2, item3 } from '../miscellaneous/motionVariants';

export const Hero = () => {

    // GET THE STATES FROM THE CONTEXT
    const { setActiveNav } = useContext(ApplicationContext)

    // SET THE REF FOR THE ELEMENT TO TRACK
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: '-50% 0px -50% 0px'
    });

    // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
    useEffect(() => {
        if (isInView) {
            setActiveNav(0);
        }
    }, [isInView, setActiveNav])

    return (
        <Flex
            id='hero'
            h='100vh'
            justifyContent='center'
            alignItems='center'
            ref={ref}
        >
            <Flex
                w={allBreakpoints}
                margin='auto'
                justifyContent='space-between'
                alignItems='center'
                flexDir={{
                    base: 'column',
                    md: 'row'
                }}

                as={motion.div}
                variants={container}
                initial='hidden'
                animate='show'
            >
                {/* LEFT */}
                <Flex
                    flexDir='column'
                    pos='relative'
                    flex={1}

                    as={motion.div}
                    variants={item2}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: true }}
                >
                    <Text>
                        Hello, I am Nankwai,
                    </Text>
                    <Text
                        as='h1'
                    >
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString('Business Intelligence')
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString('Data Analyst')
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .start()
                            }}
                            options={{ loop: true, autoStart: true }}
                        />
                    </Text>
                    <Text
                        width='70%'
                        fontWeight='semibold'
                        fontSize='1.1rem'
                    >
                        I love helping businesses grow, succeed, and increase profit, and I am skilled at leveraging technology to predict and reveal business strategy. I am open to work and interested in collaborating with businesses to help create a plan for success.
                    </Text>
                    <HeroButton />
                </Flex>

                {/* RIGHT */}
                <Flex
                    flex={1}
                    justifyContent='end'
                    alignItems='center'

                    as={motion.div}
                    variants={item3}
                    initial='hidden'
                    whileInView='show'
                    viewport={{ once: true }}
                >
                    <Illustration />
                </Flex>
            </Flex>
        </Flex>
    )
}
